<template>
    <div class="container pd-12 tx-color-03">
        <div class="content-container">
            <h4 class="tx-semibold mg-b-15 tx-color-01">Ebilling and Business Management <span class="tx-normal tx-sans tx-spacing--2">v1.3.0</span></h4>
            <p class="mb-1">Hello!! </p>
            <p class="mb-1">Welcome to Ebilling and Business Management Complete Accounting and Business Management Tool)</p>
            <p>You are reading User Manual of Ebilling and Business Management System Developed by <a href="https://www.softechpark.com" target="blank">Softechpark Pvt. Ltd.</a> If you have any questions that are beyond the scope of this help file, please feel free to <a href="https://www.softechpark.com/contact">Contct us</a> Thanks so much!</p>

            <Introduction />
            <Dashbaord />
            <NewSales />
            <ManageSales />
            <NewSalesOrder />
            <ManageSalesOrder />
            <NewPurchase />
            <ManagePurchase />
            <NewPurchaseOrder />
            <ManagePurchaseOrder />
            <NewPurchaseReturn />
            <ManagePurchaseReturn />
            <ManageItem />
            <ManageCustomer />
            <ManageSupplier />
            <Stock />
            <StockTransfer/>
            <StockAdjustoment />
            <Lead />
            <Estimate />
            <ChequePrint />
            <ContactDetils />
            <Tools />
            <Employee />
            <SalaryPayroll />
            <SalaryMaster />
            <Attendance />


            
        </div>
    </div>
</template>
<script>
import Introduction from "./GuideContentComponents/Introduction";
import Dashbaord from "./GuideContentComponents/Dashboard";
import NewSales from "./GuideContentComponents/NewSales";
import NewSalesOrder from "./GuideContentComponents/NewSalesOrder";
import ManageSales from "./GuideContentComponents/ManageSales";
import ManageSalesOrder from "./GuideContentComponents/ManageSalesOrder";
import NewPurchase from "./GuideContentComponents/NewPurchase";
import ManagePurchase from "./GuideContentComponents/ManagePurchase";
import NewPurchaseOrder from "./GuideContentComponents/NewPurchaseOrder";
import ManagePurchaseOrder from "./GuideContentComponents/ManagePurchaseOrder";
import NewPurchaseReturn from "./GuideContentComponents/NewPurchaseReturn";
import ManagePurchaseReturn from "./GuideContentComponents/ManagePurchaseReturn";
import ManageItem from "./GuideContentComponents/ManageItem";
import ManageCustomer from "./GuideContentComponents/ManageCustomer";
import ManageSupplier from "./GuideContentComponents/ManageSupplier";
import Stock from "./GuideContentComponents/Stock";
import StockTransfer from "./GuideContentComponents/StockTransfer";
import StockAdjustoment from "./GuideContentComponents/StockAdjustment";
import Lead from "./GuideContentComponents/Lead";
import Estimate from "./GuideContentComponents/Estimate";
import ChequePrint from "./GuideContentComponents/ChequePrint";
import ContactDetils from "./GuideContentComponents/ContactDetail";
import Tools from "./GuideContentComponents/Tools";
import Employee from "./GuideContentComponents/ManageEmployee";
import SalaryPayroll from "./GuideContentComponents/SalaryPayroll";
import SalaryMaster from "./GuideContentComponents/SalaryMaster";
import Attendance from "./GuideContentComponents/Attendance";

export default {
    components: { Introduction, Dashbaord, NewSales, ManageSales, NewSalesOrder, ManageSalesOrder, NewPurchase, ManagePurchase, NewPurchaseOrder, ManagePurchaseOrder,
    NewPurchaseReturn, ManagePurchaseReturn, ManageItem, ManageCustomer, ManageSupplier, Stock, StockTransfer, 
    StockAdjustoment, Lead, Estimate, ChequePrint, ContactDetils, Tools, Employee, SalaryPayroll, SalaryMaster, Attendance },
}
</script>
