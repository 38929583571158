<template>
<div class="bg-theam-primary">
    <div class="no-print">
        <SideBar />
    </div>
    <div class="content content-margin">
        <div class="fixed-header no-print">
            <Header />
        </div>
        <div class="content-body-container">
            <Content />
        </div>
    </div>
    
</div>
</template>

<script>
import SideBar from "./Components/sideBar";
import Header from "./Components/header";
import Content from "./Components/GuideContent";
export default {
    components: { SideBar, Header, Content },
}
</script>
<style>
ol li, h6 {
    font-size: 14px;
    color: #2e2e2e;
}
h5 {
    font-size: 18px;
}
p, a {
    font-size: 14px;
}
.nav-sub {
    margin-left: 30px;
    display: none;
}
.show {
    display: block;
}
.list-style-alpha {
    list-style: upper-alpha !important;
    font-weight: 400;
}
@media print {
    @page
    {
        margin: 50px !important;
    }
}

</style>
