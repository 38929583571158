<template>
    <div id="sidebarMenu" class="sidebar sidebar-fixed sidebar-doc wd-250 t-0 no-print">
      <div class="sidebar-body pd-x-20 pd-b-20 pd-t-10">
        <a href="/"><img
              src="/assets/img/logo.png"
              height="54"
              width="auto"
              padding="0"
            /></a>

        <ul class="nav nav-docs mt-3" v-for="(data, index) in SiderMenu" :key="index">
            <li class="nav-label"><span class="nav-label mg-b-5">{{data.key}}</span></li>
            <li class="nav-item" v-for="(menu, index) in data.parent" :key="index">
                <a :href="`#${menu.route}`" class="nav-link with-sub" @click="openMenu(menu)" :class="{'active':isactive == menu.slug}"> {{menu.title}}</a>
                <ul class="nav nav-docs nav-sub" :class="{'show':selectedMenuIndex == menu.slug}">
                    <li class="" v-for="(child, index) in menu.child" :key="index"><a :href="`#${child.route}`" class="nav-link" :class="{'active':clickedChild == child.slug}" @click="childClicked(child.slug)">{{child.title}}</a></li>
                </ul>
            </li>
        </ul>
      </div><!-- sidebar-body -->
    </div><!-- sidebar -->
</template>
<script>
import MenuItems from "./SideBarMenu.json"
import { mapGetters } from "vuex";
export default {
    data(){
        return{
        selectedMenu: '',
        selectedMenuIndex:'',
        clickedChild:'',
        isactive: false,
        }
    },
    computed: {
        ...mapGetters([
        "icons",
        "today",
        "dataId",
        "dataLists1",
        "dataLists8",
        "eventMessage",
        "modalId",
        "today",
        "authData",
        ]),

        SiderMenu() {
            return MenuItems.data.map((item) => {
                return item;
            })
        }
    },
    mounted(){
        console.log(this.$route);
    },
    methods: {
        openMenu(menu) {
            if(this.selectedMenuIndex == menu.slug){
                this.selectedMenuIndex = ''
            }else{
                this.selectedMenuIndex = menu.slug;
                this.clickedChild = '';
            }
            this.isactive = menu.slug;
        },
        childClicked(slug){
            this.clickedChild = slug;
        }
    },
}
    

</script>
