<template>
    <div class="header no-print">
        
        <div class="header-left">
            <a href="javascript:;" @click="$store.dispatch('toggleSidebar')" class="burger-menu">
                <i class="fas fa-bars"></i>
            </a>
            <div class="header-search">
                <input
                    type="search"
                    class="form-control"
                    placeholder="What are you looking for?"
                    style="background: none;"
                />
                <i class="fas fa-search"></i>
            </div>
        </div>
        <div class="header-right">
            <div class="dropdown dropdown-notification">
                <a href="" class="dropdown-link" data-toggle="dropdown">
                    <i class="fas fa-print" @click="print()" title="Print Slip"></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    export default {
        computed: { ...mapGetters(["icons"]), },
        components:{
        },
        methods: {
            print() {
                window.print();
            }
        }
    }
</script>